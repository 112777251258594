import AirPollution_black from "./AirPollution-black.png";
import AirPollution_white from "./AirPollution-white.png";
import BiodiversityLoss_black from "./BiodiversityLoss-black.png";
import BiodiversityLoss_white from "./BiodiversityLoss-white.png";
import BuildAndProtectSoil_black from "./BuildAndProtectSoil-black.png";
import BuildAndProtectSoil_white from "./BuildAndProtectSoil-white.png";
import ChemicalPollution_black from "./ChemicalPollution-black.png";
import ChemicalPollution_white from "./ChemicalPollution-white.png";
import ChemicalPollution_White from "./ChemicalPollution_White.png";
import CleanseTheAir_black from "./CleanseTheAir-black.png";
import CleanseTheAir_white from "./CleanseTheAir-white.png";
import Community_black from "./Community-black.png";
import Community_white from "./Community-white.png";
import Connectivity_black from "./Connectivity-black.png";
import Connectivity_white from "./Connectivity-white.png";
import Culture_black from "./Culture-black.png";
import Culture_white from "./Culture-white.png";
import CycleFreshwater_black from "./CycleFreshwater-black.png";
import CycleFreshwater_white from "./CycleFreshwater-white.png";
import Education_black from "./Education-black.png";
import Education_white from "./Education-white.png";
import Energy_black from "./Energy-black.png";
import Energy_white from "./Energy-white.png";
import EnhanceWellbeing_black from "./EnhanceWellbeing-black.png";
import EnhanceWellbeing_white from "./EnhanceWellbeing-white.png";
import EqualityInDiversity_black from "./EqualityInDiversity-black.png";
import EqualityInDiversity_white from "./EqualityInDiversity-white.png";
import ExcessiveFertilizerUse_black from "./ExcessiveFertilizerUse-black.png";
import ExcessiveFertilizerUse_white from "./ExcessiveFertilizerUse-white.png";
import Food_black from "./Food-black.png";
import Food_white from "./Food-white.png";
import FreshwaterWithdrawal_black from "./FreshwaterWithdrawal-black.png";
import FreshwaterWithdrawal_white from "./FreshwaterWithdrawal-white.png";
import HarvestEnergy_black from "./HarvestEnergy-black.png";
import HarvestEnergy_white from "./HarvestEnergy-white.png";
import Health_black from "./Health-black.png";
import Health_white from "./Health-white.png";
import HouseBiodiversity_black from "./HouseBiodiversity-black.png";
import HouseBiodiversity_white from "./HouseBiodiversity-white.png";
import Housing_black from "./Housing-black.png";
import Housing_white from "./Housing-white.png";
import IncomeAndWork_black from "./IncomeAndWork-black.png";
import IncomeAndWork_white from "./IncomeAndWork-white.png";
import LandConversion_black from "./LandConversion-black.png";
import LandConversion_white from "./LandConversion-white.png";
import Mobility_black from "./Mobility-black.png";
import Mobility_white from "./Mobility-white.png";
import Networks_black from "./Networks-black.png";
import Networks_white from "./Networks-white.png";
import OceanAcidification_black from "./OceanAcidification-black.png";
import OceanAcidification_white from "./OceanAcidification-white.png";
import OzoneLayerDepletion_black from "./OzoneLayerDepletion-black.png";
import OzoneLayerDepletion_white from "./OzoneLayerDepletion-white.png";
import PeaceAndJustice_Black from "./PeaceAndJustice-Black.png";
import PeaceAndJustice_White from "./PeaceAndJustice-White.png";
import PoliticalVoice from "./PoliticalVoice.png";
import PoliticalVoice_white from "./PoliticalVoice-white.png";
import RegulateTheTemperature_black from "./RegulateTheTemperature-black.png";
import RegulateTheTemperature_white from "./RegulateTheTemperature-white.png";
import SocialEquity_black from "./SocialEquity-black.png";
import SocialEquity_white from "./SocialEquity-white.png";
import StoreCarbon_black from "./StoreCarbon-black.png";
import StoreCarbon_white from "./StoreCarbon-white.png";
import Water_black from "./Water-black.png";
import Water_white from "./Water-white.png";

const Icons = {
  AirPollution_black,
  AirPollution_white,
  BiodiversityLoss_black,
  BiodiversityLoss_white,
  BuildAndProtectSoil_black,
  BuildAndProtectSoil_white,
  ChemicalPollution_White,
  ChemicalPollution_black,
  ChemicalPollution_white,
  CleanseTheAir_black,
  CleanseTheAir_white,
  Community_black,
  Community_white,
  Connectivity_black,
  Connectivity_white,
  Culture_black,
  Culture_white,
  CycleFreshwater_black,
  CycleFreshwater_white,
  Education_black,
  Education_white,
  Energy_black,
  Energy_white,
  EnhanceWellbeing_black,
  EnhanceWellbeing_white,
  EqualityInDiversity_black,
  EqualityInDiversity_white,
  ExcessiveFertilizerUse_black,
  ExcessiveFertilizerUse_white,
  Food_black,
  Food_white,
  FreshwaterWithdrawal_black,
  FreshwaterWithdrawal_white,
  HarvestEnergy_black,
  HarvestEnergy_white,
  Health_black,
  Health_white,
  HouseBiodiversity_black,
  HouseBiodiversity_white,
  Housing_black,
  Housing_white,
  IncomeAndWork_black,
  IncomeAndWork_white,
  LandConversion_black,
  LandConversion_white,
  Mobility_black,
  Mobility_white,
  Networks_black,
  Networks_white,
  OceanAcidification_black,
  OceanAcidification_white,
  OzoneLayerDepletion_black,
  OzoneLayerDepletion_white,
  PeaceAndJustice_Black,
  PeaceAndJustice_White,
  PoliticalVoice,
  PoliticalVoice_white,
  RegulateTheTemperature_black,
  RegulateTheTemperature_white,
  SocialEquity_black,
  SocialEquity_white,
  StoreCarbon_black,
  StoreCarbon_white,
  Water_black,
  Water_white,
};

export default Icons;
